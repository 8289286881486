import { InitState, UUID } from "gx-npm-lib";

export enum TaskStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
}

export type TasksApiPayload = {
  initId: UUID;
  initProductId: UUID;
  initState: string;
  requestedByCompanyName: string;
  requestedByUserName: string;
  surveyId: UUID;
  surveyStatus: InitState;
  type: string;
};

export type TaskObject = {
  initId: UUID;
  initProductId: UUID;
  initState: InitState;
  requestedByCompanyName: string;
  requestedByUserName: string;
  surveyId: UUID;
  surveyStatus: TaskStatus;
};
