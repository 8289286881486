import { useEffect, useState } from "react";
import { Loader, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getAsyncRequest, InitState, useUserState } from "gx-npm-lib";
import { TaskObject, TasksApiPayload, TaskStatus } from "../../types";
import NoTasksAssigned from "../survey-card/no-tasks-assigned.component";
import SurveyCards from "../survey-card/survey-cards.component";
import styles from "./tasks-dashboard.styles";

const useStyles = makeStyles(() => styles);
const TasksDashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { firstName } = useUserState();
  const [isError, setIsError] = useState<boolean>(false);
  const [isTasksDataLoading, setIsTasksDataLoading] = useState<boolean>(true);
  const [tasks, setTasks] = useState<Array<TaskObject>>([]);

  useEffect(() => {
    (async () => {
      const response = await getAsyncRequest("/api/v2/user/tasks");
      if (response?.status === 200 && Array.isArray(response.data?.data)) {
        const tasksFromApi = response.data.data.map((data: TasksApiPayload) => {
          return {
            initId: data.initId || "",
            initProductId: data.initProductId || "",
            initState: data.initState || InitState.ACTIVE,
            requestedByCompanyName: data.requestedByCompanyName || "",
            requestedByUserName: data.requestedByUserName || "",
            surveyId: data.surveyId || "",
            surveyStatus: data.surveyStatus || TaskStatus.NOT_STARTED,
          };
        });
        setTasks(tasksFromApi);
      } else {
        setIsError(true);
      }
      setIsTasksDataLoading(false);
    })();
  }, []);

  return (
    <div className={classNames("has-main-nav", classes.containerTasksDashboard)}>
      <div className={classes.contentWrapper}>
        <div className={classNames(classes.header)}>
          {!isTasksDataLoading && (
            <TypographyComponent
              styling={"p2"}
              color={"gartnerBlue"}
              boldness={"medium"}
              rootClassName={classNames(classes.commonPadding, classes.spacing, classes.title)}
            >
              {t("Welcome back")}
              {!!firstName && `, ${firstName}`}
            </TypographyComponent>
          )}
        </div>
        <div className={classNames(classes.commonPadding, classes.spacing)}>
          <div className={classes.tasksWrapper}>
            <TypographyComponent styling={"h3"} boldness={"medium"} color={"carbon"}>
              {t("My tasks")}
            </TypographyComponent>
          </div>
        </div>
        <div className={classNames(classes.commonPadding, classes.spacing)}>
          {isTasksDataLoading && (
            <div className={classNames(classes.loadingContainer)}>
              <Loader />
            </div>
          )}
          {!isTasksDataLoading && (
            <div>
              {tasks.length !== 0 && <SurveyCards tasks={tasks} />}
              {tasks.length === 0 && <NoTasksAssigned />}
            </div>
          )}
        </div>
        <SnackbarBanner isOpen={isError} isDefaultErrorMessage={true} type="ERROR" setIsOpen={setIsError} />
      </div>
    </div>
  );
};
export default TasksDashboard;
