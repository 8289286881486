import { colorPalette } from "gx-npm-common-styles";

const noTasksAssignedStyles = {
  wrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column" as const,
    "& $h3Header": {
      marginTop: 40,
      textAlign: "center",
    },
  },
  divider: {
    backgroundColor: "var(--neutrals-silver-dddddd, #DDD)",
    borderBottom: `2px solid ${colorPalette.neutrals.silver.hex}`,
    borderRadius: 8,
    marginBottom: 55,
  },
  h3Header: {},
};

export default noTasksAssignedStyles;
