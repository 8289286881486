import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider, GartnerFooter } from "gx-npm-ui";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import TasksDashboard from "./ui/tasks-dashboard/tasks-dashboard.component";

const generateClassName = createGenerateClassName({ seed: "tasks-dashboard-app" });
const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <TasksDashboard />
        <GartnerFooter />
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
