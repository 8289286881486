// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BLgKh5I6kdEhK77vzsaF{margin:0 12px 0 12px;white-space:nowrap;width:108px}.aYFZ8y3z65Wb_2whsKzt{position:relative;top:-6px}.BhcfXFwSJhY3Bzh98XOa{height:27px;margin-top:13px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/weighted-priority-drawer/priorities-form/weight-selection/weight-selection.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,kBAAA,CACA,WAAA,CAEF,sBACE,iBAAA,CACA,QAAA,CAEF,sBACE,WAAA,CACA,eAAA","sourcesContent":[".container {\n  margin: 0 12px 0 12px;\n  white-space: nowrap;\n  width: 108px;\n}\n.indicator {\n  position: relative;\n  top: -6px\n}\n.textImgWrapper {\n  height: 27px;\n  margin-top: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BLgKh5I6kdEhK77vzsaF`,
	"indicator": `aYFZ8y3z65Wb_2whsKzt`,
	"textImgWrapper": `BhcfXFwSJhY3Bzh98XOa`
};
export default ___CSS_LOADER_EXPORT___;
