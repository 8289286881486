import { makeStyles } from "@material-ui/core";
import * as singleSpa from "single-spa";
import classNames from "classnames";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import SurveyClipboard from "../../assets/images/survey-clipboard.svg";
import styles from "./survey-card-item.styles";
import SurveyCardStatus from "./survey-card-status.component";
import { TaskStatus } from "../../types";
import { InitState, UUID } from "gx-npm-lib";
import { FC } from "react";

type SurveyCardItemProps = {
  initId: UUID;
  initProductId: UUID;
  initState: InitState;
  isLastCard: boolean;
  requestedByCompanyName: string;
  requestedByUserName: string;
  surveyStatus: TaskStatus;
};
const useStyles = makeStyles(() => styles);
const SurveyCardItem: FC<SurveyCardItemProps> = ({
  initId,
  initProductId,
  initState,
  isLastCard,
  requestedByCompanyName,
  requestedByUserName,
  surveyStatus,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigateToSurvey = () => {
    singleSpa.navigateToUrl(`/s/questionnaire/${initId}/product/${initProductId}/overview`);
  };
  return (
    <div className={classNames(classes.container, isLastCard && "is-last")}>
      <SurveyCardStatus initState={initState} status={surveyStatus} />
      <div className={classes.statusContainer}>
        <div className={classes.item}>
          <div className={classes.clipBoardAndInfoWrapper}>
            <img src={SurveyClipboard} alt="survey clipboard" />
            <div className={classes.infoWrapper}>
              <div className={classNames(classes.name)}>
                <TypographyComponent color={"carbon"} styling={"p2"} boldness={"medium"}>
                  {requestedByCompanyName}
                </TypographyComponent>
              </div>
              <div className={classNames(classes.title)}>
                <TypographyComponent styling={"p4"} color={"coal"} rootClassName={classes.title}>
                  {t("Requested by")}
                  {` `}
                  <TypographyComponent styling={"p4"} boldness={"medium"} element={"span"} color={"carbon"}>
                    {requestedByUserName}
                  </TypographyComponent>
                </TypographyComponent>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.item}>
          <Button onClick={navigateToSurvey} rootClassName={classNames("btn-primary", "gx-dragon-continue-btn")}>
            {t("OPEN QUESTIONNAIRE")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SurveyCardItem;
