import { colorPalette } from "gx-npm-common-styles";

const colorBlack = colorPalette.basic.black.hex;
const colorIron = colorPalette.neutrals.iron.hex;

const tasksDashboardStyles = {
  containerTasksDashboard: {
    display: "flex",
    flexDirection: "column" as const,
    minHeight: "calc(100vh - 128px)",
    color: colorBlack,
    paddingLeft: 74,
    background: colorPalette.neutrals.quartz.hex,
  },
  header: {
    background: colorPalette.basic.white.hex,
    color: colorBlack,
    height: 75,
  },
  commonPadding: {
    paddingLeft: "24px",
    paddingRight: "24px",
    transition: "padding 0.5s",
    "@media (min-width:1240px)": {
      paddingLeft: "56px",
      paddingRight: "56px",
    },
  },
  title: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  tasksWrapper: {
    paddingTop: 20,
    marginBottom: 32,
  },
  spacing: {
    maxWidth: "1512px",
    margin: "0 auto",
  },
  loadingContainer: {
    position: "relative" as const,
    width: "100%",
    paddingTop: 42,
  },
  footer: {
    "&.no-tasks": {
      position: "absolute" as const,
      bottom: 0,
      width: "100%",
    },
    marginTop: "auto",
    borderTop: `1px solid ${colorIron}`,
    height: "73px",
  },
  contentWrapper: {},
};

export default tasksDashboardStyles;
