import { Fragment } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import NoTaskSvg from "../../assets/images/no-tasks-icon.svg";
import styles from "./no-tasks-assigned.styles";
import { TypographyComponent } from "gx-npm-ui";

const useStyles = makeStyles(() => styles);
const NoTasksAssigned = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classNames(classes.divider)} />
      <div className={classNames(classes.wrapper)}>
        <img src={NoTaskSvg} alt="no tasks" />

        <TypographyComponent
          styling={"h4"}
          color={"iron"}
          boldness={"medium"}
          rootClassName={classNames(classes.h3Header)}
        >
          {t("You don’t have any assigned tasks")}
        </TypographyComponent>
      </div>
    </Fragment>
  );
};

export default NoTasksAssigned;
